import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider"

export default class Slider {

  constructor(selector) {
    this.sliders = document.querySelectorAll(selector);
    if (!this.sliders) {
      return;
    }

    for (let i = 0; i < this.sliders.length; ++i) {
      const slider = this.sliders[i];
      if (slider.dataset.sliderInitialized) {
        continue;
      }
      slider.dataset.sliderInitialized = true
      const sliderItems = slider.querySelectorAll('.slider--item');

      if (sliderItems.length > 1) {
        const sliderInstance = tns({
          container: slider,
          mode: 'carousel',
          speed: 750,
          autoplay: false,
          autoplayButtonOutput: false,
          controls: true,
          touch: true,
          nav: false,
        });

      }
    }
  }

}
