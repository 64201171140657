import { gsap, ScrollTrigger, DrawSVGPlugin, Power1 } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);

class RegionMap extends HTMLElement {

  constructor() {
    super();

    this.played = false;

    this.button = this.querySelectorAll('.button');

  }


  connectedCallback() {
    this.setupAnimation();


    ScrollTrigger.create({
      // markers: true,
      trigger: this,
      id: 'region-map',
      start: "top 33%",
      end: "bottom top",
      onEnter: (self) => {
        this.playAnimation();
      },
      onEnterBack: (self) => {
        this.playAnimation();
      },
    });
  }


  disconnectedCallback() {
    if (ScrollTrigger.getById('region-map')) {
      ScrollTrigger.getById('region-map').kill();
    }
  }


  setupAnimation() {
    this.timeline = gsap.timeline({
      paused: true,
      callbackScope: this,
      onComplete: () => {
        this.played = true;
      }
    });


    // RIVER
    this.timeline.fromTo('[data-name="river-1"]', { drawSVG: "0%" }, { drawSVG: "100%", duration: 2, ease: Power1.easeInOut });
    this.timeline.fromTo('[data-name="river-2"]', { drawSVG: "0%" }, { drawSVG: "100%", duration: 0.4, ease: Power1.easeInOut }, '-=0.3');
    this.timeline.fromTo('[data-name="river-typo"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=1.5');
    // /RIVER

    // CITY
    this.timeline.fromTo('[data-name="city-outline"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=1');
    this.timeline.fromTo('[data-name="city-pattern"] line', { drawSVG: "0%" }, { drawSVG: "100%", duration: 0.5, ease: Power1.easeInOut, stagger: 0.05 }, '-=1');
    this.timeline.fromTo('[data-name="city-typo"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=1');
    this.timeline.fromTo('[data-name="city-point"]', { opacity: 0, scale: 2, transformOrigin: "center center" }, { opacity: 1, scale: 1, duration: 0.5, ease: Power1.easeInOut }, '-=0.5');
    this.timeline.fromTo('[data-name="city-connector"]', { drawSVG: "0%" }, { drawSVG: "100%", duration: 0.75, ease: Power1.easeInOut }, '-=0.5');
    this.timeline.fromTo('[data-name="city-logo-bottom"]', { opacity: 0, }, { opacity: 1, duration: 0.5, ease: Power1.easeInOut }, '-=0.1');
    this.timeline.fromTo('[data-name="city-logo-top"]', { drawSVG: "0%" }, { drawSVG: "100%", duration: 0.5, ease: Power1.easeInOut });
    // /CITY


    // WAY+REGION
    this.timeline.fromTo('#wayMasker', { drawSVG: "0%" }, { drawSVG: "100%", duration: 2.5, ease: Power1.easeInOut });

    this.timeline.fromTo('[data-name="region-outline"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=0.5');
    this.timeline.fromTo('[data-name="region-bg"]', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut }, '-=1');
    this.timeline.fromTo('[data-name="region-pattern"] line', { drawSVG: "0%" }, { drawSVG: "100%", duration: 0.5, ease: Power1.easeInOut, stagger: 0.05 }, '-=0.5');
    this.timeline.fromTo('[data-name="region-point"]', { opacity: 0, scale: 2, transformOrigin: "center center" }, { opacity: 1, scale: 1, duration: 0.5, ease: Power1.easeInOut }, '-=0.5');
    this.timeline.fromTo('[data-name="region-connector"]', { drawSVG: "0%" }, { drawSVG: "100%", duration: 0.5, ease: Power1.easeInOut }, '-=0.5');
    this.timeline.fromTo('[data-name="region-typo"]', { opacity: 0 }, { opacity: 1, ease: Power1.easeInOut }, '-=0.1');
    // /WAY+REGION

    // BUTTON
    this.timeline.fromTo('.region-button', { opacity: 0 }, { opacity: 1, duration: 1, ease: Power1.easeInOut });
    // /BUTTON

  }


  playAnimation() {
    if (this.played) {
      return;
    }
    this.timeline.play();
  }

}

// Define the new web component
if ('customElements' in window) {
  customElements.define('region-map', RegionMap);
}
