export default class Contactform {

  constructor(selector) {
    this.form = document.querySelector(selector)
    if (!this.form) {
      return;
    }
    if (this.form.dataset.contactformInitialized) {
      return;
    }
    this.form.dataset.contactformInitialized = true

    const requiredInputs = this.form.querySelectorAll('[required]');
    for (let index = 0; index < requiredInputs.length; index++) {
      const requiredInput = requiredInputs[index];
      const requiredInputMessage = this.form.querySelector('.form--alert[data-target="' + requiredInput.name + '"]');
      
      if (requiredInputMessage && requiredInputMessage.dataset.message) {
        requiredInput.addEventListener('invalid', (event) => {
          requiredInputMessage.innerHTML = requiredInputMessage.dataset.message;
        });

        requiredInput.addEventListener('keyup', (event) => {
          this.checkValidity(requiredInput, event);
        });
        requiredInput.addEventListener('change', (event) => {
          this.checkValidity(requiredInput, event);
        });
      }
    }
  }


  checkValidity(requiredInput, event) {
    const requiredInputMessage = this.form.querySelector('.form--alert[data-target="' + requiredInput.name + '"]');
    if (requiredInput.validity.valid) {
      requiredInputMessage.innerHTML = '&nbsp;';
    }
  }

}