export default class NavMobile {

  constructor() {
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');

    if (this.body.dataset.navMobileInitialized) {
      return;
    }

    this.body.dataset.navMobileInitialized = true;
    this.triggerButton = document.querySelector('.navigation--trigger');
    this.triggers = document.querySelectorAll('.navigation--trigger-overlay');
    this.nav = document.querySelector('.header--navigation');
    this.active = false;


    if (this.nav && this.triggers) {
      this.body.addEventListener('click', (event) => {
        if (this.active) {
          setTimeout(() => {
            this.html.classList.remove('navigation__active');
            this.html.classList.remove('overflow-hidden');
            this.nav.classList.replace('-translate-x-full', 'translate-x-0');
          }, 100)
          setTimeout(() => {
            this.nav.scrollTop = 0;
          }, 400);
          this.active = false;
        }
        else {
          let triggerHit = false
          this.triggers.forEach((trigger, key) => {
            if (event.target == trigger) {
              triggerHit = true;
            }
          });

          if (triggerHit) {
            this.active = true;
            this.html.classList.add('navigation__active');
            this.html.classList.add('overflow-hidden');
            this.nav.classList.replace('translate-x-0', '-translate-x-full');
          }
        }

        this.triggerButton.setAttribute('aria-pressed', this.active);
        this.triggerButton.setAttribute('aria-label', this.active ? 'Navigation ausblenden' : 'Navigation einblenden');
        this.nav.setAttribute('aria-hidden', !this.active);
      });
    }

  }
}
